export const Login = {
  mobileNubmerLabel: "Mobile Number",
  mobileNubmerPlaceholder: "Enter mobile number",
  passwordLabel: "Password",
  passwordPlaceholder: "Password",
  loginHeader: "Login",
  loginButtonText: "Login",
  loggingButtonText: "Loggging...",
  logoutText: "Logout",
  incorrectUserAndPasswordMsg: "Username or Password is in-correct",
};

export const Match = {
  matches: "Matches",
  matchId: "Match ID: ",
  searchByPlayernamePlaceholder: "Search by player name",
  published: {
    startDate: "Start Date",
    startTime: "Start Time",
    weekLeague: "Week League",
    totalCoins: "Total Coins",
    status: "Status",
    prize: "Prize",
    name: "Name",
    team1name: "Team 1",
    team2name: "Team 2",
    matchId: "Match ID",
    view: "View",
    totalTeams: "Total Teams",
    prizeDisbursed: "Prize Disbursed",
    usersJoinedCount: "Users Joined",
    filterByMatchIdPlaceholder: "Filter by match ID",
    transferTitle: "Transfers",
    transferInLabel: "In",
    transferOutLabel: "Out",
    prizeDisbursedLabel: "Prize Disbursed",
    successfullySyncedMatch: "Successfully synced match data",
  },
  unPublished: {
    startDate: "Start Date",
    weekLeague: "Week League",
    special: "Special",
    specialMatch: "Special Matches",
    status: "Status",
    prize: "Prize",
    name: "Name",
    team1name: "Team 1",
    team2name: "Team 2",
    matchId: "Match ID",
    action: "Action",
    configure: "configure",
    filterByMatchIdPlaceholder: "Filter by match ID",
    unpublishedPlayerMessage: (playerName) =>
      `Player ${playerName} is unpublished, please update player position`,
    unpublishedPlayersMessage: (playerNames) =>
      `Players ${playerNames} are unpublished, please update players position`,
    specialMatchConfirmMsg: (isSpecialMatch, matchId) =>
      `Are you sure you want to ${
        isSpecialMatch ? "Unmark" : "Mark"
      } match having id: ${matchId} as special match?`,
    specialMatchSuccessMsg: (isSpecialMatch) =>
      `Match ${isSpecialMatch ? "unmarked" : "marked"} as special successfully`,
  },
  configure: {
    team1name: "Team 1",
    team2Name: "Team 2",
    team1Color: "Team 1 Color",
    team2Color: "Team 2 Color",
    totalCoins: "Total Coins",
    visible: "Visible",
    startDate: "Start Date",
    specialMatch: "Special Match",
    fromText: "From",
    toText: "To",
    amountText: "Amount",
    action: "Action",
    publishContent: "Publishing match with saved configuration",
    unpublishContent: "Unpublishing the match",
    publishContentTg: "Publishing match with saved configuration for Telegram",
    unpublishContentTg: "Unpublishing the match for Telegram",
    calculateScoreContent:
      "Calculate the score of a match having status Live or Completed",
    prizeDisbursedContent:
      "Disbursed prize if total teams more than 0 & prize for the completed match has not already been distributed",
    saveContent: "Save the details configured for match, publish it after save",
    downloadReportContent:
      "Download the players score report for live/completed matches",
    downloadMatchWinningReport:
      "Download the match report for all prize disbursed matches with allotted and disbursed data",
    downloadLeaderboardContent: "Download all user teams for this match",
    syncMatchContent: "Synced unpublished and excluded player from the match",
    publishDetailsConfirmMessage: (isPublishedMatch) =>
      ` Are you sure, you want to ${
        isPublishedMatch ? "Unpublish" : "Publish"
      } the details?`,
    publishOrUnpublishDetailsMessage: (isPublishedMatch) =>
      `${
        isPublishedMatch ? "Successfully unpublished" : "Successfully published"
      }`,
    calculateScoreConfirmMessage: "Are you sure, you want to calculate score?",
    disbursePrizeConfirmMessage:
      "Are you sure you want to distribute winning prize?",
  },
  player: {
    teamName: "Team Name",
    name: "Name",
    totalFutyScore: "T. PT.",
    profile: "Profile",
    last5Score: "L. 5 PT.",
    form: "Form",
    futyStar: "Futy Star",
    totalMatchScore: "PT.",
    lastMatchScore: "L. PT.",
    injuryStatus: "Injury Status",
    injuryType: "Injury Type",
    position: "Position",
    positionFilterPlaceholder: "filter by position",
    totalFutyScoreTooltip: "Total futy score",
    last5ScoreTooltip: "Last 5 score",
    lastMatchScoreTooltip: "Last match score",
    totalMatchScoreTooltip: "Match score",
    transferInOrOut: "Transfer (In/Out)",
    published: "Published",
    action: "Action",
  },
};

export const Player = {
  profile: "Profile",
  name: "Name",
  position: "Player Position",
  team: "Team Name",
  action: "Action",
  details: "Details",
  player: "Players",
};

export const Reward = {
  brand: "Brand",
  code: "Code",
  coins: "Coins",
  euro: "Euro",
  amount: "Amount",
  username: "Username",
  updatedAt: "Updated At",
  expiryDate: "Expiry Date",
  actions: "Actions",
  rewardsText: "Rewards",
  emailSent: "Email Sent",
  status: "Status",
  errorText: "Error",
  allReward: "All Rewards",
  userRedeem: "Users Redeem",
  minAmount: 1,
  maxAmount: 9999,
  minCoins: 1,
  maxCoins: 99999,
  downloadUserRedeemedReportContent: "Download user redeemed reward report",
  addReward: {
    addRewardText: "Add Reward",
    amountText: "Amount",
    amountPlaceholder: "amount",
    coinsText: "Coins",
    coinsPlaceholder: "coins",
  },
};

export const Stats = {
  description: "Description",
  value: "Value",
  referralDateRangeLabel: "Date range filter for users joined",
  referralDateRangePlaceholder: "Select date range",
};

export const Brand = {
  image: "Image",
  name: "Name",
  category: "Category",
  actions: "Actions",
  update: "Update",
};

export const adminRole = "CENTRAL_ADMIN";
export const MenuItems = {
  matches: "Matches",
  teams: "Teams",
  players: "Players",
  futyPoints: "Futy Player Points",
  rewards: "Rewards",
  stats: "Stats",
  bonusCredit: "Bonus Credit",
  referrals: "Referrals",
  pushNotification: "Push Notification",
  userManagement: "Manage User",
  leagues: "Leagues",
  userIdentity: "User Identity",
  miniLeagues: "Mini Leagues",
  quiz: "Quiz",
  goalfest: "Goalfest",
};

export const Action = {
  save: "Save",
  saving: "Saving...",
  publish: "Publish",
  publishing: "Publishing...",
  unPublish: "Unpublish",
  unPublishing: "Unpublishing...",
  publishTg: "Publish Telegram",
  publishingTg: "Publishing Telegram... ",
  unPublishTg: "Unpublish Telegram",
  unPublishingTg: "Unpublishing Telegram...",
  goBack: "Go Back",
  submit: "Submit",
  submitting: "Submitting",
  send: "Send",
  sending: "Sending...",
  calculateScore: "Calculate Score",
  calculatingScore: "Calculating Score...",
  disbursePrize: "Disburse Prize",
  disbursingPrize: "Disbursing Prize...",
  upload: "Upload",
  uploading: "Uploading...",
  update: "Update",
  updating: "Updating...",
  search: "Search",
  searching: "Searching...",
  download: "Download",
  downloading: "Downloading...",
  fetchLeaguesAndSeasonFromOpta: "Fetch leagues and season from opta",
  fetchingLeaguesAndSeasonFromOpta: "Fetching leagues and season from opta...",
  downloadingScore: "Downloading score...",
  downloadScore: "Download Score",
  downloadingReport: "Downloading report...",
  downloadReport: "Download Report",
  downloadLeaderboard: "Download leaderboard",
  downloadingLeaderboard: "Downloading leaderboard...",
  refresh: "Refresh",
  refreshing: "Refreshing...",
  populate: "Populate",
  populating: "Populating...",
  delete: "Delete",
  deleting: "Deleting...",
  add: "Add",
  adding: "Adding...",
  resend: "Resend",
  resending: "Resending...",
  markActive: "Mark active",
  markingActive: "Marking active...",
  refreshStatus: "Refresh status",
  refreshingStatus: "Refreshing status...",
  confirmUser: "Confirm user",
  confirmingUser: "Confirming user...",
  clearCache: "Clear cache",
  clearingCache: "Clearing cache...",
  approve: "Approve",
  approving: "Approving...",
  reject: "Reject",
  rejecting: "Rejecting...",
  syncMatch: "Sync match",
  syncingMatch: "Syncing match...",
  ok: "Ok",
  cancel: "Cancel",
  addNewPrize: "Add new prize",
  uploadImage: "Upload Image",
  mark: "Mark",
  unMark: "Unmark",
  marking: "Marking...",
  unMarking: "Unmarking...",
  refreshPlayerScore: "Refresh player score",
  refreshingPlayerScore: "Refreshing player score...",
};

export const MatchStatus = {
  SCHEDULED: "scheduled",
  LIVE: "live",
  COMPLETED: "completed",
  POSTPONED: "postponed",
  ABANDONED: "abandoned",

  filterValue: {
    SCHEDULED: "Scheduled",
    LIVE: "Live",
    COMPLETED: "Completed",
    POSTPONED: "Postponed",
    ABANDONED: "Abandoned",
  },
};

export const MatchStatusOption = [
  { label: "Scheduled", value: MatchStatus.filterValue.SCHEDULED },
  { label: "Live", value: MatchStatus.filterValue.LIVE },
  { label: "Completed", value: MatchStatus.filterValue.COMPLETED },
  { label: "Postponed", value: MatchStatus.filterValue.POSTPONED },
  { label: "Abandoned", value: MatchStatus.filterValue.ABANDONED },
];

export const UserRewardStatus = {
  COMPLETED: "Completed",
  SCHEDULED: "Scheduled",
  FAILED: "Failed",
};

export const UserRewardStatusOption: any = [
  { label: "Completed", value: UserRewardStatus.COMPLETED },
  { label: "Scheduled", value: UserRewardStatus.SCHEDULED },
  { label: "Failed", value: UserRewardStatus.FAILED },
];

export const Credit = {
  creditConfig: {
    type: "Type",
    credits: "Credits",
    expiryDate: "Expiry Date",
    createdAt: "Created At",
    bonus: "Bonus",
    expiryDatePlaceholder: "Please choose expiry date",
    hour: "Hour",
    minute: "Minutes",
    allCreditConfig: "All Credit Config",
    bonusCredit: "Credit",
  },
};

export const Notification = {
  pushNotify: {
    title: "Title",
    message: "Message",
    date: "Date",
    allNotification: "All Notification",
    messageMaxChar: 100,
    titleMaxChar: 30,
  },
};

export const MatchNotification = {
  title: "Title",
  message: "Message",
  messageMaxChar: 100,
  titleMaxChar: 30,
};
export const ManageUsers = {
  userManagement: "User Management",
  mobile: "Mobile",
  name: "Name",
  email: "Email",
  userName: "Username",
  password: "Password",
  searchByMobilePlaceholder: "Search by Mobile number",
  searchByUsernamePlaceholder: "Search by username",
  searchByEmailPlaceholder: "Search by email",
  passwordHelperText:
    "Note*: Password must contain atleast 8 character with combination of 1 uppercase, 1 lowercase, 1 numeric and 1 special characters",
  mobileNumberHelperText: "Note*: Please enter mobile number with country code",
  successfullyConfirmUser: "Successfully confirmed the user",
  confirmUserContent: "Confirm the unverified user without OTP",
};

export const TeamsConfig = {
  teams: {
    logo: "Logo",
    name: "Name",
    code: "Code",
    teamColor: "Team Color",
    teamShirtNumberColor: "Team Jersey Number Color",
    action: "Action",
    edit: "Edit",
    teamName: "Team Name",
    teamLogo: "Team Logo",
    teamsText: "Teams",
  },
};

export const Leagues = {
  leaguesConfig: {
    leaguesConfigText: "Leagues Config",
    leaguesText: "Leagues",
    seasonsText: "Seasons",
    seasonActive: "Season active",
    lastSyncAt: "Last sync at",
    successfullyRefreshedPlayer: "Successfully refreshed the player score",
    selectLeagueToRefreshPlayer: "Please select league to refresh player score",
    successfullyPopulateLeagueSeason:
      "Successfully submit the request for populating leagues and season",
  },
};

export const hourOption = [
  { label: "00", value: 0 },
  { label: "01", value: 1 },
  { label: "02", value: 2 },
  { label: "03", value: 3 },
  { label: "04", value: 4 },
  { label: "05", value: 5 },
  { label: "06", value: 6 },
  { label: "07", value: 7 },
  { label: "08", value: 8 },
  { label: "09", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
];

export const minutesOption = [
  { label: "00", value: 0 },
  { label: "01", value: 1 },
  { label: "02", value: 2 },
  { label: "03", value: 3 },
  { label: "04", value: 4 },
  { label: "05", value: 5 },
  { label: "06", value: 6 },
  { label: "07", value: 7 },
  { label: "08", value: 8 },
  { label: "09", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
  { label: "32", value: 32 },
  { label: "33", value: 33 },
  { label: "34", value: 34 },
  { label: "35", value: 35 },
  { label: "36", value: 36 },
  { label: "37", value: 37 },
  { label: "38", value: 38 },
  { label: "39", value: 39 },
  { label: "40", value: 40 },
  { label: "41", value: 41 },
  { label: "42", value: 42 },
  { label: "43", value: 43 },
  { label: "44", value: 44 },
  { label: "45", value: 45 },
  { label: "46", value: 46 },
  { label: "47", value: 47 },
  { label: "48", value: 48 },
  { label: "49", value: 49 },
  { label: "50", value: 50 },
  { label: "51", value: 51 },
  { label: "52", value: 52 },
  { label: "53", value: 53 },
  { label: "54", value: 54 },
  { label: "55", value: 55 },
  { label: "56", value: 56 },
  { label: "57", value: 57 },
  { label: "58", value: 58 },
  { label: "59", value: 59 },
];

export enum MatchConfigModel {
  SCORE_CALCULATION = 1,
  PUBLISH_UNPUBLISH = 2,
  DISBURSE_PRIZE = 3,
}

export enum UserFieldUpdateModel {
  PASSWORD = 1,
  EMAIL = 2,
}

export enum MiniLeagueModel {
  PUBLISH = 1,
  UNPUBLISH = 2,
  DISBURSE_PRIZE = 3,
}

export const defaultPageSize = 50;
export const DateRange = {
  last7Days: "Last 7 days",
  next7Days: "Next 7 days",
  next15Days: "Next 15 days",
  startDate: "Choose start date",
  endDate: "Choose end date",
  dateText: "Date Range",
  placeholder: "Select date range",
};

export enum DateRangeEnum {
  last7Days = "last7Days",
  last15Days = "last15Days",
  next7Days = "next7Days",
  next15Days = "next15Days",
  lastWeek = "lastWeek",
  lastMonth = "lastMonth",
  thisMonth = "thisMonth",
}

export enum DaysToGetEnum {
  prev = "prev",
  next = "next",
}

export const DateRangeEnumLabel = {
  [DateRangeEnum.last7Days]: "Last 7 days",
  [DateRangeEnum.last15Days]: "Last 15 days",
  [DateRangeEnum.lastWeek]: "Last Week",
  [DateRangeEnum.next7Days]: "Next 7 days",
  [DateRangeEnum.next15Days]: "Next 15 days",
  [DateRangeEnum.lastMonth]: "Last Month",
  [DateRangeEnum.thisMonth]: "This Month",
};

export const Referrals = {
  referralCodeMinLength: 4,
  refefrralCodeMaxLength: 30,
  messageMinLength: 0,
  messageMaxLength: 150,
  referralTypeLable: "Referral Type",
  messageLable: (maxLength) =>
    `Message (max ${maxLength} char message displayed to end user)`,
  messagePlacerholder: "Enter some message",
  bonus: "Bonus",
  succussfullMessageUpdate: "Succussfully update the message",
  error: {
    messageError: "Please enter some message",
    typeError: "Please select referral type",
    duplicatePromotionCodeError: "Promotion code already exists",
    messageMaxLengthError: (maxLength) =>
      `Please keep message length upto ${maxLength} char`,
  },
};

export const max_photo_size = 2097152;
export const ImageUpload = {
  message: {
    max_size_error: (size) => `Please upload an image smaller than ${size}MB`,
    noImageSelectError: "Please select an image",
    uploadImageError: "Error uploading Image",
  },
};

export const leaguesSeasonPopulateMessage = (
  selectedLeague,
  selectedSeason,
) => {
  return `This action will populate Matches, Teams and Players for ${selectedLeague?.label}'s season ${selectedSeason?.label}. Are you sure you want to continue ?`;
};

export const referralTypeOptions = [
  { label: "Campaign", value: "CAMPAIGN" },
  { label: "Pub-Campaign", value: "PUBCAMPAIGN" },
];

export const ALPHANUMERIC_UPPERCASE_REGEX = /[^a-z0-9]/gi;

export const playerPositionOptions = [
  { label: "Attacker", value: "attacker" },
  { label: "Defender", value: "defender" },
];
export const HEADER = {
  messages: {
    successfullyClearCache: "Successfully cleared the cache",
  },
};
export enum TransferTypeEnum {
  IN = "In",
  OUT = "Out",
}

export enum StatsTabKeysEnum {
  RETENTION = "retention",
  ID_VERIFICATION = "idVerification",
  USERS = "users",
  MATCH = "match",
  REFERRAL = "referral",
}

export const StatsTabTitles = {
  [StatsTabKeysEnum.ID_VERIFICATION]: "Id Verification",
  [StatsTabKeysEnum.RETENTION]: "Retention View",
};

export const searchTimeDelay = 1000;
export const minSearchTimeDelay = 200;
export const UserIdentity = {
  username: "Username",
  mobile: "Mobile",
  status: "status",
  rejectedReason: "Rejected reason",
  view: "View",
  document: "Document",
  documents: "Documents",
  action: "Action",
  users: "Users",
  loading: "Loading...",
  usernameSearchPlaceholder: "Search by username",
  mobileSeachPlaceholder: "Search by mobile",
  statusFilterPlaceholder: "Filter by status",
  approveConfirmationMessage: (userData) =>
    `Are you sure you want to approve the User ${userData?.username ?? ""} ?`,
  rejectConfirmationMessage: (userData) =>
    `Are you sure you want to reject the User ${userData?.username ?? ""} ?`,
  successfullApproveUserMsg: "Successfully approve the user",
  successfullRejectUserMsg: "Successfully reject the user",
  identityId: "Identity ID: ",
  onfidoStatus: "Onfido status",
  emptyRejectTypeError: "Please select rejection type",
  approveRadioLabel: "Approve",
  rejectRadioLabel: "Reject",
  matchedApplicants: "Matched Applicants",
  reviewReason: "Review reason",
  noValue: "-",
  noDocumentAvailable: "No document available",
  documentNumberInfo: (document, totalDocument) =>
    `Document ${document} of ${totalDocument}`,
  errorDisplayingDocument: "Error displaying the document",
};

export enum UserIdentityStatusEnum {
  INITIATED = "Initiated",
  UPLOADED = "Uploaded",
  PROCESSING = "Processing",
  VERIFIED = "Verified",
  REJECTED = "Rejected",
  REVIEWBYADMIN = "ReviewByAdmin",
}

enum RejectionTypeEnum {
  KNOWNFACE = "KnownFace",
  MISMATCH = "Mismatch",
}

export const UserIdentityStatusOption = [
  {
    label: UserIdentityStatusEnum.INITIATED,
    value: UserIdentityStatusEnum.INITIATED,
  },
  {
    label: UserIdentityStatusEnum.UPLOADED,
    value: UserIdentityStatusEnum.UPLOADED,
  },
  {
    label: UserIdentityStatusEnum.PROCESSING,
    value: UserIdentityStatusEnum.PROCESSING,
  },
  {
    label: UserIdentityStatusEnum.VERIFIED,
    value: UserIdentityStatusEnum.VERIFIED,
  },
  {
    label: UserIdentityStatusEnum.REJECTED,
    value: UserIdentityStatusEnum.REJECTED,
  },
  { label: "Review by admin", value: UserIdentityStatusEnum.REVIEWBYADMIN },
];

export enum UserIdentityApprovalType {
  APPROVE = UserIdentityStatusEnum.VERIFIED,
  REJECT = UserIdentityStatusEnum.REJECTED,
}

export const RejectionTypeOption = [
  {
    label: "Known face",
    value: RejectionTypeEnum.KNOWNFACE,
  },
  {
    label: RejectionTypeEnum.MISMATCH,
    value: RejectionTypeEnum.MISMATCH,
  },
];

export const DEFAULT_FILE_TYPE = {
  imagePng: "image/png",
};

export const EMPTY_ERROR = {
  isError: false,
  errorMsg: "",
};

export const SVG_COLOR = {
  green: "#00ff00",
  red: "#ff0000",
};

export const MiniLeagues = {
  addMiniLeague: "Add Mini League",
  newLeaguePathId: "new",
  featured: {
    featuredTab: "Featured",
    kickOffMatch: "Kickoff Match",
    featuredMatch: "Featured Match",
    leaguePrize: "League Prize",
    managerPrize: "Manager Prize",
    name: "Name",
    labelName: "Name",
    miniLeagueLogo: "Logo",
    kickoffmatch: "Kickoff Match",
    nextMatchToPublish: "Next Match to Publish",
    labelKickoffmatch: "Kickoff Match",
    published: "Published",
    totalUsers: "Total Users",
    prizeDisbursed: "Prize Disbursed",
    edit: "Edit",
    uniqueCode: "Unique Code",
    joinLeagueUrl: "Join League URL",
    leagueCode: "League Code",
    team1: "Team 1",
    team2: "Team 2",
    matchId: "Match ID",
    startDate: "Start Date",
    startTime: "Start Time",
    action: "Action",
  },
  messages: {
    successfullySavedData: "Successfully saved the data",
    successfullyPublishedData: "Successfully published the mini league",
    successfullyUnpublishedData: "Successfully unpublished the mini league",
    isConfirmPublish: "Are you sure you want to publish the mini league?",
    isConfirmUnpublish: "Are you sure you want to unpublish the mini league?",
    isConfirmDisbursePrize: "Are you sure you want to disburse the prize?",
    leagueNameError: "Please enter league name",
    kickOffMatchError: "Please select kick off match",
    uniqueCodeError: "Please enter unique code",
    managerPrizeError: "Please enter at least one entery in manager prize list",
    leaguePrizeError: "Please enter at least one entery in league prize list",
    successfullyDisbursedPrize: "Successfully disbursed the prize",
    kickOffMatchDetailsNotSaved:
      "Please save the kick off match details first, and then publish the league",
    managerPrizeEntryLimitError: "Manager prize can't have more than 1 entry",
    minUniqueCodeLengthError: (minLength) =>
      `Minimum ${minLength} length char required`,
    maxUniqueCodeLengthError: (maxLength) =>
      `Maximum char length can be ${maxLength}`,
    uniqueCodeHelperText: (minLength, maxLength) =>
      `Length should be between ${minLength} to ${maxLength} char`,
    noMatchAvailable: "Matches not exist",
    allMatchesPublished: "N/A",
  },
  managerPrizeEntryLimit: 1,
  uniqueCodeMinLength: 6,
  uniqueCodeMaxLength: 15,
};

export const prizeConfigMessage = {
  prizeShouldBeNumber: "Prize configuration should be a number",
  prizeCantNegative: "Prize configuration can't be negative",
  fromShouldLessThanTo:
    "Prize config is not valid as From can't be greater than To",
  overlappingRanks:
    "Prize configuration is not valid as ranks cannot overlap. Please ensure the 'From' value starts where the previous 'To' value ends.",
};
export enum DAYTIME_PHASE {
  START = "start",
  END = "end",
}
export const FILTER_CONSTANTS = {
  filterBySeasonPlaceholder: "Filter by season",
  filterByLeaguePlaceholder: "Filter by league",
  filterByTeams: "Filter by teams",
  filterByDate: "Filter by date",
};

export enum TrueOrFalse {
  TRUE = "true",
  FALSE = "false",
}

export const Seprator = {
  dash: "-",
  comma: ",",
  colon: ":",
};

export enum EventTypeEnum {
  POSSESSIONS_LOST = "Possessions Lost",
  YELLOW_CARD = "Yellow Card",
  RED_CARD = "Red Card",
  ERRORS_LEADING_TO_GOALS = "Errors Leading to Goals",
  OWN_GOALS = "Own Goals",
  MINUTES_PLAYED = "Minutes Played",
  PLAYED_30_MIN = "Played 30 Min",
  PLAYED_60_MIN = "Played 60 Min",
  PLAYED_90_MIN = "Played 90 Min",
  GOALS = "Goals",
  ASSISTS = "Assists",
  BIG_CHANCES_CREATED = "Big Chances Created",
  SHOTS_ON_TARGET = "Shots on Target",
  HIT_THE_WOODWORK = "Hit the Woodwork",
  GOALS_FROM_OUTSIDE_THE_BOX = "Goals from Outside the Box",
  PENALTIES_MISSED = "Penalties Missed",
  WINNING_GOAL = "Winning Goal",
  BIG_CHANCES_MISSED = "Big Chances Missed",
  TACKLES = "Tackles",
  INTERCEPTIONS = "Interceptions",
  OFF_THE_LINE = "Off The Line",
  CLEAN_SHEET_1ST_HALF = "Clean Sheet 1st Half",
  CLEAN_SHEET_2ND_HALF = "Clean Sheet 2nd Half",
  SAVES = "Saves",
  PENALTY_SAVES = "Penalty Saves",
  SHOT_BLOCKS = "Shot Blocks",
  ACCURATE_CROSSES = "Accurate Crosses",
  TWO_GOALS_CONCEDED = "2 Goals Conceded",
  FOULS_COMMITTED = "Fouls Committed",
  PENALTY_CONCEDED = "Penalty Conceded",
}

export enum OrderEnum {
  ASC = "ASC",
  DESC = "DESC",
}

export enum PrizeTypeEnum {
  ABSOLUTE = "Absolute",
  PERCENTAGE = "Percentage",
}

export const QuestionsArrayLength = 5;

export enum ContestTypeEnum {
  FANTASY = "Fantasy",
  TG_FANTASY = "Tg_Fantasy",
}
