import { useState, useEffect } from "react";
import Typography from "../../../common/components/Typography";
import { fetchQuizList } from "../../service";
import ReactTable from "../../../common/components/ReactTable/ReactTable";
import moment from "moment";
import TableWrapper from "../../../common/components/ReactTable/TableWrapper";
import { EMPTY_ERROR, QuestionsArrayLength } from "../../../common/constants";
import { HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TrueFalseIconViewer } from "../../../common/components/TrueFalseIconViewer";
export const QuizList = () => {
  const [quizList, setQuizList] = useState<any>({
    data: null,
    isLoading: false,
    error: EMPTY_ERROR,
  });
  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };
  useEffect(() => {
    getQuizList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuizList = async () => {
    setQuizList({
      ...quizList,
      isLoading: true,
      error: EMPTY_ERROR,
    });
    const resData = await fetchQuizList({
      platform: "Admin",
    });
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setQuizList((prev) => ({
        ...prev,
        isLoading: false,
        data: resData?.data?.data,
      }));
    } else {
      setQuizList({
        ...quizList,
        data: null,
        isLoading: false,
        error: { errorMsg: resData?.message, isError: true },
      });
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };
  const columns = [
    {
      accessorKey: "team1Name",
      header: "Team 1",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "team2Name",
      header: "Team 2",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "quizId",
      header: "Quiz Id",
      cell: (info) => info.getValue(),
      canSort: false,
    },
    {
      accessorKey: "isPublished",
      header: "Published",
      cell: (info) => (
        <TrueFalseIconViewer value={info?.getValue() ? true : false} />
      ),
      // canSort: false,
    },
    {
      accessorKey: "matchStartDate",
      header: "Start Date",
      cell: (info) => new Date(info.getValue()).toLocaleDateString(),
      // canSort: false,
    },
    {
      accessorKey: "matchStartTime",
      header: "Start Time",
      cell: (info) => info?.getValue()?.slice(0, 5),
      // canSort: false,
    },
    {
      accessorKey: "quizCreatedAt",
      header: "Created At",
      cell: (info) => moment(info.getValue()).format("DD/MM/YYYY (HH:mm)"),
      // canSort: false,
    },
    {
      accessorKey: "prizeDisbursedAt",
      header: "Prize Disbursed",
      cell: (info) => {
        const row = info.row.original;
        const date = info.getValue() ? moment(info.getValue()) : null;
        const disbursedDate = date?.isValid()
          ? date?.format("DD/MM/YYYY (HH:mm)")
          : "";
        console.log("row.prizeDisbursed", row.prizeDisbursed);
        return (
          <div className="d-flex align-items-center gap-2">
            <TrueFalseIconViewer value={row.prizeDisbursed ? true : false} />
            {row.prizeDisbursed ? `\u{1F4C5} ${disbursedDate}` : null}
          </div>
        );
      },
    },
    {
      id: "navigate",
      header: "Actions",
      cell: (info) => {
        const quizId = info.row.original.id;
        const quiz = quizList?.data?.find((q) => q.id === quizId);
        const quizObj = {
          matchId: quiz?.matchId,
          questions:
            quiz?.questions?.map((question) => ({
              //   questionNumber: question?.questionNumber,
              question: question?.question,
              eventType: question?.eventType,
              win_sort_order: question?.win_sort_order,
              options: question?.options?.map((option) => ({
                // optionNumber: option?.optionNumber,
                playerId: option?.playerId,
                multiplier: option?.multiplier,
              })),
            })) || [],
        };
        const questionsLength = quizObj?.questions?.length;

        // Add empty entries if questions less than 5 questions
        if (questionsLength < QuestionsArrayLength) {
          for (let i = questionsLength; i < QuestionsArrayLength; i++) {
            quizObj.questions.push({
              question: "",
              eventType: "",
              win_sort_order: "",
              options: Array.from({ length: 3 }, () => ({
                playerId: "",
                multiplier: 0,
              })),
            });
          }
        }
        return (
          <Link
            to={`/quiz/${info.row.original.quizId}`}
            state={{ quizObj: quizObj, edit: true }}
          >
            <Button size="sm" variant="link">
              Configure
            </Button>
          </Link>
        );
      },
      canSort: false,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="pageTitle" className="pb-2">
          Quiz List
        </Typography>
        <Link to="/quiz/create">
          <Button variant="primary"> Create Quiz</Button>
        </Link>
      </div>
      <TableWrapper>
        <ReactTable
          isLoading={quizList?.isLoading}
          data={quizList?.data || []}
          hasError={quizList?.error?.isError}
          errorMessage={quizList?.error?.errorMsg}
          columns={columns}
          totalRows={quizList?.data?.totalCount}
        />
      </TableWrapper>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};
