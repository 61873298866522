import { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./style.scss";
import Typography from "../../../common/components/Typography";
import { Button, Spinner } from "react-bootstrap";
import { fetchPlayerList } from "../../../Players/service";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  disbursePrizeQuiz,
  fetchQuiz,
  getAllUpcomingSeasonMatchesList,
  publishQuiz,
  saveQuiz,
  updateQuiz,
} from "../../service";
import { CustomStatusCode, HttpStatus } from "../../../utils/constants";
import ReactToastr from "../../../common/components/ReactToaster";
import {
  Action,
  EventTypeEnum,
  MatchStatus,
  OrderEnum,
  PrizeTypeEnum,
  QuestionsArrayLength,
} from "../../../common/constants";
import Select from "react-select";
import { useSelector } from "react-redux";
import { getActiveSeason } from "../../../LeaguesConfigure/components/leaguesUtils";
import { CommonMessage } from "../../../common/message";
import PrizeConfig from "../../../common/components/PrizeConfig";
import ConfirmationModal from "../../../common/components/Modal/ConfirmationModel";
import { formatDate } from "../../../Referrals/component/ReferralsTable";

export const QuizInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { edit } = location?.state || {};
  const leaguesMetadata = useSelector((state: any) => state.leagues);
  const [leaguesOption, setLeaguesOption] = useState(
    leaguesMetadata.leaguesSeason,
  );
  const [seasonsOption, setSeasonsOption] = useState([]);
  const [selectedLeagueOption, setSelectedLeagueOption] = useState(null);
  const [selectedSeasonOption, setSelectedSeasonOption] = useState(null);
  const [upcomingLeagueSeasonMatches, setUpcomingLeagueSeasonMatches] =
    useState([]); // [matches, set]
  const [playerList, setPlayerList] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPublishLoading, setIsPublishLoading] = useState<boolean>(false);
  const [isPrizeLoading, setIsPrizeLoading] = useState<boolean>(false);

  const [showToaster, setShowToaster] = useState<any>({
    show: false,
    heading: "",
    message: "",
    variant: "",
    route: "",
  });
  const [prizeList, setPrizeList] = useState([]);
  const [isPlayerLoading, setIsPlayerLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<any>({
    title: "",
    handler: () => {
      return;
    },
  });

  const [resultData, setResultData] = useState({
    data: null,
    isLoading: false,
    error: null,
  });

  const { quizId } = useParams();

  useEffect(() => {
    const fetchQuizAndPlayers = async () => {
      if (!quizId || !edit) return;

      // Fetch the quiz data
      try {
        setIsLoading(true);
        setResultData({ data: null, isLoading: true, error: null });

        const resData = await fetchQuiz({ quizId });

        if (
          resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
          resData?.status === HttpStatus.RESOURCE_CREATED
        ) {
          const quizData = resData?.data?.data;

          setResultData({
            data: quizData,
            isLoading: false,
            error: null,
          });

          // Fetch players only if the quiz data has the required information
          if (quizData?.matchId && quizData?.teams?.length === 2) {
            const fetchPlayerPayload = {
              teamId1: quizData.teams[0]?.id,
              teamId2: quizData.teams[1]?.id,
            };

            setPrizeList(quizData?.prize);

            fetchPlayersOnMatchChange(fetchPlayerPayload);
          }
        } else {
          throw new Error(resData?.message || "Failed to fetch quiz data.");
        }
      } catch (error) {
        console.error("Error:", error);
        setResultData({
          data: null,
          isLoading: false,
          error: error.message || "An unexpected error occurred.",
        });
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: error.message || "Something went wrong, please try again.",
          variant: "danger",
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuizAndPlayers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  const handleToasterClose = () => {
    setShowToaster((prev) => ({
      ...prev,
      show: false,
      message: "",
      variant: "",
      route: "",
    }));
  };

  const quizData = {
    matchId: resultData?.data?.match?.id || "",
    rewardType: resultData?.data?.rewardType || "",
    questions:
      resultData?.data?.questions?.map((question) => ({
        question: question?.question || "",
        eventType: question?.eventType || "",
        win_sort_order: question?.win_sort_order || "",
        options:
          question.options?.map((option) => ({
            playerId: option?.playerId || "",
            multiplier: option?.multiplier || 0,
            playerName: option?.playerInformation?.playerName || "",
          })) || [],
      })) || [],
  };

  const initialValues = edit
    ? quizData
    : {
        matchId: "",
        rewardType: "",
        questions: Array.from({ length: QuestionsArrayLength }, () => ({
          question: "",
          eventType: "",
          win_sort_order: "",
          options: Array.from({ length: 3 }, () => ({
            playerId: "",
            multiplier: 0,
            playerName: "",
          })),
        })),
      };
  const QuizSchema = Yup.object().shape({
    matchId: Yup.string().required("Match ID is required"),
    rewardType: Yup.string().notRequired(),
    questions: Yup.array().of(
      Yup.object().shape({
        question: Yup.string().required("Question is required"),
        eventType: Yup.string().required("Event Type is required"),
        win_sort_order: Yup.string().required("Win Order is required"),
        options: Yup.array()
          .of(
            Yup.object().shape({
              playerId: Yup.string().required("Option is required"),
              multiplier: Yup.number()
                .required("Score is required")
                .min(1, "Score must be at least 1")
                .test(
                  "is-decimal",
                  "Only up to 2 decimal places are allowed.",
                  (value) => {
                    return (
                      value === undefined ||
                      /^\d+(\.\d{1,2})?$/.test(value.toString())
                    );
                  },
                ),
              playerName: Yup.string().notRequired(),
            }),
          )
          .test(
            "unique-playerIds",
            "Player IDs in options must be unique for the question.",
            (options) => {
              if (!options || options.length === 0) return true;
              const playerIds = options.map((option) => option.playerId);
              const uniquePlayerIds = new Set(playerIds);
              return playerIds.length === uniquePlayerIds.size;
            },
          ),
      }),
    ),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useEffect(() => {
    setLeaguesOption(leaguesMetadata.leaguesSeason);
  }, [leaguesMetadata]);

  const getUpcomingMatches = async (season) => {
    setIsLoading(true);
    const resData: any = await getAllUpcomingSeasonMatchesList({
      query: {
        leagueSeasonId: season?.id,
        upcoming: true,
        isQuizListed: true,
      },
      page: {
        skip: 0,
        limit: 40,
      },
    });

    setUpcomingLeagueSeasonMatches(
      resData?.data?.data?.matches.map((match: any) => ({
        label: `${match.teamId1.shortCode} vs ${match.teamId2.shortCode}`,
        value: match.id,
        teams: `${match.teamId1.shortCode} vs ${match.teamId2.shortCode}`,
        date: formatDate(match.startDate),
        teamId1: match.teamId1.id,
        teamId2: match.teamId2.id,
      })),
    );
    setIsLoading(false);
  };
  const handleLeaguesChange = (e) => {
    setIsLoading(true);
    const seasonOption = e?.leagueSeasons?.map((item) => ({
      ...item,
      label: item?.name,
      value: item?.id,
    }));
    const activeSeason = getActiveSeason(seasonOption);
    getUpcomingMatches(activeSeason);
    setSelectedLeagueOption(e);
    setSelectedSeasonOption(activeSeason);
    setSeasonsOption(seasonOption || []);
    setIsLoading(false);
  };

  const publishIngQuiz = async (publish: boolean) => {
    try {
      const resData = await publishQuiz({ quizId, publish });

      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `Quiz ${
            publish ? "published" : "unpublished"
          } successfully!`,
          variant: "success",
        }));
        setIsPublishLoading(true);

        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
        setIsPublishLoading(false);
      }
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "An unexpected error occurred, please try again.",
        variant: "danger",
      }));
      setIsPublishLoading(false);
    }
  };

  const prizeDisbursing = async () => {
    try {
      setIsPrizeLoading(true);
      const resData = await disbursePrizeQuiz({ quizId });

      if (
        resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
        resData?.status === HttpStatus.RESOURCE_CREATED
      ) {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: "Prize disbursed successfully!",
          variant: "success",
        }));

        setIsPrizeLoading(false);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setShowToaster((prev) => ({
          ...prev,
          show: true,
          message: `${
            resData?.message ?? "Something went wrong, please try again"
          }`,
          variant: "danger",
        }));
      }
      setIsPrizeLoading(false);
    } catch (error) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: "An unexpected error occurred, please try again.",
        variant: "danger",
      }));
      setIsPrizeLoading(false);
    }
  };

  const fetchPlayersOnMatchChange = async (match) => {
    setIsPlayerLoading(true);
    const resData = await fetchPlayerList({
      query: {
        leagueTypeId:
          resultData?.data?.match?.leagueTypeId ??
          selectedLeagueOption?.id ??
          "9b2561f8-54db-45d6-af3f-c6df6fa78f5a",
        teamIds: [match.teamId1, match.teamId2],
      },
      page: { skip: 0, limit: 100 },
    });
    setPlayerList(
      resData.data?.data?.players.map((player) => {
        return {
          label: player.firstName + " " + player.lastName,
          value: player.id,
        };
      }),
    );
    setIsPlayerLoading(false);
  };

  const updateOrSave = async (values: any, { resetForm }: any) => {
    setIsLoading(true);
    const payload = {
      matchId: values.matchId,
      rewardType: values.rewardType,
      prize: prizeList,
      questions: values.questions.map((question, index) => ({
        questionNumber: index + 1,
        question: question.question,
        eventType: question.eventType,
        win_sort_order: question.win_sort_order,
        options: question.options.map((option, optionIndex) => ({
          optionNumber: optionIndex + 1,
          playerId: option.playerId,
          multiplier: option.multiplier,
        })),
      })),
    };

    const resData: any = edit
      ? await updateQuiz({ ...payload, quizId }, {})
      : await saveQuiz(payload, {});
    if (
      resData?.status === HttpStatus.REQUEST_SUCCEEDED ||
      resData?.status === HttpStatus.RESOURCE_CREATED
    ) {
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `Quiz has been ${edit ? "edited" : "created"} successfully`,
        variant: "success",
      }));

      setIsLoading(false);

      if (edit) {
        setTimeout(() => {
          navigate(0);
        }, 2000);
      } else {
        setTimeout(() => {
          navigate(`/quiz`);
        }, 2000);
      }
    } else {
      if (resData?.status !== CustomStatusCode.sessionExpire)
        setIsLoading(false);
      setShowToaster((prev) => ({
        ...prev,
        show: true,
        message: `${
          resData?.message ?? "Something went wrong, please try again"
        }`,
        variant: "danger",
      }));
    }
  };

  if (isLoading || resultData?.isLoading) {
    return (
      <div className="react-table-loader">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!resultData?.data && edit) {
    return <div className="react-table-loader">Data unavailable</div>;
  }

  const customOption = (props: any) => {
    const { data, isFocused, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 12px",
          backgroundColor: isFocused ? "#f0f0f0" : "white",
          cursor: "pointer",
        }}
      >
        <span>{data.teams}</span>
        <span style={{ color: "#6c757d", fontSize: "0.85em" }}>
          {data.date}
        </span>
      </div>
    );
  };

  const modalHandler = (title, handler) => {
    setModalContent({
      ...modalContent,
      title: title,
      handler: handler,
    });
    setShowModal(true);
  };
  return (
    <>
      <ConfirmationModal
        variant="alert"
        show={showModal}
        title={modalContent?.title}
        handleClick={modalContent.handler}
        handleClose={() => setShowModal(false)}
        secondaryBtnText="Cancel"
        handleSecondaryBtnClick={() => setShowModal(false)}
        btnText="Confirm"
        isLoading={isPrizeLoading || isPublishLoading}
      />
      <Button
        className="d-flex align-items-start px-0 dynamic-width-button"
        variant="link"
        onClick={() => navigate(-1)}
      >
        {Action.goBack}
      </Button>
      <Typography variant="heading3" className="pb-2">
        {edit ? "Edit Quiz" : "Create Quiz"}
      </Typography>
      {!edit && (
        <section className="d-flex gap-4 pb-2">
          <Select
            placeholder="Filter by leagues"
            options={leaguesOption}
            value={selectedLeagueOption}
            onChange={(e) => {
              handleLeaguesChange(e);
            }}
            isLoading={leaguesMetadata.isLoading}
            loadingMessage={() => <Spinner variant="primary" size="sm" />}
            noOptionsMessage={() =>
              leaguesMetadata?.isError
                ? leaguesMetadata?.errorMsg
                : CommonMessage.noData
            }
            isDisabled={edit}
          />

          <Select
            placeholder="Filter by season"
            options={seasonsOption}
            value={selectedSeasonOption}
            onChange={(e) => {
              setSelectedSeasonOption(e);
            }}
            isDisabled={edit}
          />
        </section>
      )}

      <Formik
        initialValues={initialValues}
        validationSchema={QuizSchema}
        onSubmit={updateOrSave}
        enableReinitialize
      >
        {({ values, setFieldValue, isValid }) => (
          <Form>
            <div
              className={`d-flex align-items-center gap-2 ${
                edit ? "pb-4" : "pb-1"
              }`}
            >
              <Typography variant="heading3">
                {!edit
                  ? "Match ID *"
                  : `${resultData?.data?.teams[0].name} V/S ${resultData?.data?.teams[1].name}`}
              </Typography>
              {edit && (
                <div className="d-flex align-items-center pb-2">
                  {resultData?.data?.isPublished ? (
                    <span className="badge rounded-pill text-bg-success px-2 py-1">
                      Published
                    </span>
                  ) : (
                    <span className="badge rounded-pill text-bg-secondary px-2 py-1 text-light">
                      Yet to be Published
                    </span>
                  )}
                </div>
              )}
            </div>
            <section className="d-flex flex-column w-100">
              <div className=" d-flex gap-2">
                {!edit && (
                  <Select
                    className="flex-grow-1"
                    placeholder="Select a match"
                    options={upcomingLeagueSeasonMatches}
                    name="matchId"
                    isLoading={isLoading}
                    components={{
                      Option: customOption,
                    }}
                    onChange={(e) => {
                      setFieldValue("matchId", e.value);
                      fetchPlayersOnMatchChange(e);
                    }}
                  />
                )}
                <Select
                  className="flex-grow-1"
                  value={
                    values.rewardType
                      ? { label: values.rewardType, value: values.rewardType }
                      : null
                  }
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  options={[
                    {
                      label: PrizeTypeEnum.ABSOLUTE,
                      value: PrizeTypeEnum.ABSOLUTE,
                    },
                    {
                      label: PrizeTypeEnum.PERCENTAGE,
                      value: PrizeTypeEnum.PERCENTAGE,
                    },
                  ]}
                  placeholder="Select Prize Type"
                  name="rewardType"
                  isLoading={isLoading}
                  onChange={(e) => {
                    setFieldValue("rewardType", e.value);
                  }}
                />
              </div>
              <div className="w-100 d-flex align-items-center gap-2 px-2 "></div>

              <PrizeConfig
                isLoading={isLoading || resultData.isLoading}
                isError={false}
                errorMsg="Something went wrong, please try again"
                prizeList={prizeList}
                setPrizeList={setPrizeList}
                isReadOnly={resultData?.data?.isPublished}
                setShowToaster={setShowToaster}
                isDisabledDeleteRow={resultData?.data?.isPublished}
                isFromFieldDisabled={resultData?.data?.isPublished}
                isToFieldDisabled={resultData?.data?.isPublished}
                canSetDefaultToAndFromValue={true}
              />
            </section>

            <div className="divider-container"></div>
            <FieldArray name="questions">
              {({ insert, remove, push }) => (
                <div>
                  {values.questions.map((question, index) => (
                    <div key={index}>
                      <Typography variant="pageTitle" className="p-1">
                        Question {index + 1}
                      </Typography>
                      <div className="row px-1">
                        <div className="col-md-6">
                          <Typography variant="heading6" className="">
                            Winning Order
                          </Typography>
                          <div className="input-container mb-4 w-50">
                            <Select
                              options={Object.values(OrderEnum).map(
                                (order) => ({
                                  label: order,
                                  value: order,
                                }),
                              )}
                              placeholder="Select Win Order"
                              className="quiz-option-input"
                              name={`questions.${index}.win_sort_order`}
                              onChange={(e) => {
                                setFieldValue(
                                  `questions.${index}.win_sort_order`,
                                  e.value,
                                );
                              }}
                              isDisabled={resultData?.data?.isPublished && edit}
                              value={Object.values(OrderEnum)
                                .map((order) => ({
                                  label: order,
                                  value: order,
                                }))
                                .find(
                                  (option) =>
                                    option.value ===
                                    values.questions[index].win_sort_order,
                                )}
                            />

                            <ErrorMessage
                              name={`questions.${index}.win_sort_order`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <Typography variant="heading6" className="p-1">
                            Event Type
                          </Typography>
                          <div className="input-container mb-4 w-100">
                            <Select
                              options={Object.values(EventTypeEnum).map(
                                (eventType) => ({
                                  label: eventType,
                                  value: eventType,
                                }),
                              )}
                              placeholder="Select Event Type"
                              className="quiz-option-input w-50"
                              name={`questions.${index}.eventType`}
                              isDisabled={resultData?.data?.isPublished && edit}
                              onChange={(e) => {
                                setFieldValue(
                                  `questions.${index}.eventType`,
                                  e.value,
                                );
                              }}
                              value={Object.values(EventTypeEnum)
                                .map((eventType) => ({
                                  label: eventType,
                                  value: eventType,
                                }))
                                .find(
                                  (option) =>
                                    option.value ===
                                    values.questions[index].eventType,
                                )}
                            />

                            <ErrorMessage
                              name={`questions.${index}.eventType`}
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="relative mb-2">
                        <Field
                          className="quiz-question fs-6"
                          name={`questions.${index}.question`}
                          placeholder="Enter Question"
                          disabled={resultData?.data?.isPublished && edit}
                        />
                        <ErrorMessage
                          name={`questions.${index}.question`}
                          component="div"
                          className="absolute py-2 px-1 top-0 right-0 text-danger"
                        />
                      </div>
                      <FieldArray name={`questions.${index}.options`}>
                        {({ insert, remove, push }) => (
                          <div className="pt-2">
                            {values.questions?.[index]?.options &&
                            values.questions[index].options.length > 0 ? (
                              values.questions[index].options.map(
                                (option, optIndex) => (
                                  <div
                                    className="quiz-options-container quiz-option"
                                    key={optIndex}
                                  >
                                    <Typography variant="heading6">
                                      Option {optIndex + 1}
                                    </Typography>
                                    <div className="input-container">
                                      <Select
                                        value={playerList.find(
                                          (option) =>
                                            option.value ===
                                            values.questions[index].options[
                                              optIndex
                                            ].playerId,
                                        )}
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        getOptionValue={(option) =>
                                          option.value
                                        }
                                        options={playerList}
                                        name={`questions.${index}.options.${optIndex}.playerId`}
                                        placeholder={`Select Option ${
                                          optIndex + 1
                                        }`}
                                        className="quiz-option-input"
                                        isDisabled={
                                          resultData?.data?.isPublished && edit
                                        }
                                        isLoading={isPlayerLoading}
                                        onChange={(e) =>
                                          setFieldValue(
                                            `questions.${index}.options.${optIndex}.playerId`,
                                            e.value,
                                          )
                                        }
                                      />
                                      <ErrorMessage
                                        name={`questions.${index}.options.${optIndex}.playerId`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>

                                    <Typography variant="heading6">
                                      Max Score Multiplier
                                    </Typography>
                                    <div className="input-container">
                                      <Field
                                        className="quiz-option-score"
                                        name={`questions.${index}.options.${optIndex}.multiplier`}
                                        placeholder="Score"
                                        type="number"
                                        disabled={
                                          resultData?.data?.isPublished && edit
                                        }
                                      />
                                      <ErrorMessage
                                        name={`questions.${index}.options.${optIndex}.multiplier`}
                                        component="div"
                                        className="error-message"
                                      />
                                    </div>
                                  </div>
                                ),
                              )
                            ) : (
                              <div>No options available.</div>
                            )}
                            <div className="divider-container"></div>
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
            <div className="d-flex justify-content-start gap-3 w-75">
              <Button
                type="submit"
                variant="primary"
                className="w-25"
                disabled={isLoading || resultData?.data?.isPublished}
              >
                {isLoading
                  ? Action.saving
                  : edit
                  ? "Update Quiz"
                  : "Create Quiz"}
              </Button>
              {edit && (
                <Button
                  type="button"
                  variant="primary"
                  className="w-25"
                  disabled={
                    isLoading || resultData?.data?.prizeDisbursed || !isValid
                  }
                  onClick={() => {
                    if (!resultData?.data?.isPublished) {
                      modalHandler(
                        `Are you sure you want to publish the quiz ?`,
                        () => publishIngQuiz(true),
                      );
                    } else {
                      modalHandler(
                        `Are you sure you want to unpublish the quiz ?`,
                        () => publishIngQuiz(false),
                      );
                    }
                  }}
                >
                  {isLoading
                    ? !resultData?.data?.isPublished
                      ? Action.publishing
                      : Action.unPublishing
                    : !resultData?.data?.isPublished
                    ? "publish"
                    : "unPublish"}
                </Button>
              )}
              {edit && (
                <Button
                  type="button"
                  variant="primary"
                  className="w-25"
                  disabled={
                    isLoading ||
                    resultData?.data?.prizeDisbursed ||
                    !resultData?.data?.isPublished ||
                    !isValid ||
                    resultData?.data?.match?.status === MatchStatus.COMPLETED
                  }
                  onClick={() => {
                    if (
                      resultData?.data?.isPublished &&
                      !resultData?.data?.prizeDisbursed
                    ) {
                      modalHandler(
                        `Are you sure you want to distribute the prize?`,
                        () => prizeDisbursing(),
                      );
                    }
                  }}
                >
                  {isLoading ? Action.disbursingPrize : Action.disbursePrize}
                </Button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <ReactToastr
        show={showToaster?.show}
        message={showToaster?.message}
        closeButton={true}
        title={showToaster?.heading}
        toastBg={showToaster?.variant}
        onClose={handleToasterClose}
        position="top-center"
        autohide
      />
    </>
  );
};
